<template>
  <v-container class="pt-5 mb-10" fluid style="height: 100vh">
    <v-row>
      <v-col cols="3">
        <v-card class="my-0">
          <v-toolbar elevation="0" dark>
            <v-icon color="blue lighten-3">mdi-message-outline </v-icon>
            <v-spacer></v-spacer>

            <v-spacer></v-spacer>

            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-toolbar>
          <v-card style="height: 75vh; overflow: auto">
            <v-list two-line>
              <!-- v-model="selected" -->
              <v-list-item-group>
                <template v-for="(item, index) in items">
                  <v-list-item
                    :key="item.user_name"
                    @click="onSelectChat(item)"
                    :class="item.status == 'In Queue' ? 'online-status' : ''"
                    :active-class="
                      item.user_room_id == current_user_room_id
                        ? 'pink--text'
                        : ''
                    "
                  >
                    <template v-slot:default="{ active }">
                      <v-avatar
                        v-if="item.user_name"
                        color="indigo"
                        class="mr-2"
                      >
                        <span class="white--text">{{
                          item.user_name.slice(0, 2)
                        }}</span>
                      </v-avatar>
                      <v-avatar v-else color="indigo" class="mr-2">
                        <span class="white--text">Un</span>
                      </v-avatar>

                      <v-list-item-content>
                        <!-- v-text="item.user_name" -->
                        <v-list-item-title
                          v-text="item.user_name"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          class="text--primary"
                          v-text="item.subject"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card elevation="3" height="70vh" class="transparent pa-5 pb-0">
          <div
            style="height: 90%"
            class="message-container d-flex flex-column-reverse"
          >
            <div v-for="(message, idx) in current_message_array" :key="idx">
              <!-- v-if="message.user_role == 'user'" -->

              <div
                class="right-chat-text-wraper"
                v-if="message.user_role == 'user'"
              >
                <div class="d-flex justify-end float-right" style="width: 100%">
                  <div class="chat-text">
                    <p class="py-2 mb-0 text-justify" style="min-with: 100%">
                      {{ message.content }}
                    </p>
                  </div>
                </div>
                <img
                  class="chat-user right-chat-user"
                  src="@/assets/boy.svg"
                  alt=""
                />
              </div>

              <div class="chat-text-wraper" v-else>
                <div class="chat-text">
                  <p class="py-2 mb-0 text-justify">
                    {{ message.content }}
                  </p>
                </div>
                <img class="chat-user" src="@/assets/helpline.svg" alt="" />
              </div>
            </div>
          </div>
        </v-card>
        <v-card
          class="d-flex justify-space-around align-center transparent text-center mt-0 pl-2 pr-5"
        >
          <input
            type="text"
            class="ml-2"
            placeholder="Reply..."
            v-model="content"
            @keyup.enter="sendMessage"
          />
          <v-btn icon>
            <v-icon medium color="#64B5F6" @click.prevent="sendMessage"
              >mdi-send</v-icon
            >
          </v-btn>
        </v-card>
      </v-col>

      <v-col
        cols="3"
        class="my-2 px-0"
        style="background-color: #444444; height: 85vh"
      >
        <v-toolbar
          elevation="0"
          dark
          height="250"
          style="background-color: #424242"
          class="pt-2"
        >
          <v-row>
            <v-col class="text-center" cols="12">
              <v-avatar height="150" width="150">
                <img
                  :src="
                    current_user.profile_picture
                      ? current_user.profile_picture
                      : require(`@/assets/pic.jpg`)
                  "
                  alt="John"
                />
              </v-avatar>
            </v-col>
            <v-col cols="12" class="text-center">
              <p class="blue--text" v-if="current_user.user_name">
                {{
                  current_user.user_name.charAt(0).toUpperCase() +
                  current_user.user_name.slice(1)
                }}
              </p>
            </v-col>
          </v-row>
        </v-toolbar>

        <div style="background-color: #424242" class="mt-3">
          <div class="py-5 px-2" style="background-color: #cfd8dc">
            <v-row>
              <v-col cols="12" sm="2" class="py-0">
                <p class="pb-0">Contact</p>
              </v-col>
              <v-col class="py-0">
                <p class="pb-0 mb-0" v-if="current_user.user_phone_number">
                  :
                  <span class="ml-2">{{ current_user.user_phone_number }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2" class="py-0">
                <p class="pb-0 mb-0">Mail</p>
              </v-col>
              <v-col class="py-0">
                <p class="pb-0 mb-0" v-if="current_user.user_email">
                  : <span class="ml-2">{{ current_user.user_email }}</span>
                </p>
              </v-col>
            </v-row>
          </div>

          <div
            v-if="current_last_order"
            class="py-5 mx-0 px-2"
            style="background-color: #4b64ab"
          >
            <v-row>
              <v-col cols="4" class="py-0">
                <p class="pb-0 white--text">Area</p>
              </v-col>
              <v-col cols="8" class="py-0">
                <p
                  class="pb-0 mb-0 white--text"
                  v-if="current_last_order && current_last_order.area"
                >
                  : <span class="ml-2">{{ current_last_order.area }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0">
                <p class="pb-0 white--text">Location</p>
              </v-col>
              <v-col cols="8" class="py-0">
                <p
                  class="pb-0 mb-0 white--text"
                  v-if="current_last_order && current_last_order.location"
                >
                  : <span class="ml-2">{{ current_last_order.location }}</span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="py-0">
                <p class="pb-0 mb-0 white--text">Address</p>
              </v-col>
              <v-col cols="8" class="py-0">
                <p
                  class="pb-0 mb-0 white--text"
                  v-if="current_last_order && current_last_order.address"
                >
                  : <span class="ml-2">{{ current_last_order.address }}</span>
                </p>
              </v-col>
            </v-row>
          </div>

          <!-- v-if="Object.keys(current_last_order).length" -->
          <div class="pt-5 px-2" v-if="Object.keys(current_last_order).length">
            <v-row>
              <v-col cols="4" class="py-0">
                <p class="pb-0 mb-0 white--text">Last Order</p>
              </v-col>
              <v-col cols="8" class="py-0">
                <p
                  class="mb-0 white--text"
                  v-if="current_last_order.price_total"
                >
                  :
                  <span class="ml-2"
                    >{{ current_last_order.price_total }} BDT</span
                  >
                </p>
                <div class="d-flex flex-row justify-space-between">
                  <p
                    class="mb-0 white--text text-caption"
                    v-if="current_last_order.date"
                  >
                    <span class="ml-2"> {{ current_last_order.date }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="current_last_order.order_count">
              <v-spacer></v-spacer>
              <v-btn color="#4b64ab" small @click.prevent="onSeeHistory"
                >See history</v-btn
              >
            </v-row>
          </div>
          <!-- v-else -->
          <div v-else>
            <p class="mb-0 py-5 white--text text-center">
              This user didn't placed any order.
            </p>
          </div>
        </div>
      </v-col>

      <v-dialog v-model="send_support" width="500">
        <v-card>
          <v-app-bar dark color="#4b64ab">
            <v-toolbar-title>Forword this request to</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon @click="closeDialog" color="white"> mdi-close </v-icon>
            </v-btn>
          </v-app-bar>

          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <div v-if="all_department_list.length" class="d-flex flex-row">
              <v-col cols="6">
                <p class="mb-0 mt-2 font-weight-medium">
                  Available Departments :
                </p>
              </v-col>
              <v-col>
                <v-select
                  v-model="forward_department"
                  :items="all_department_list"
                  item-text="department_name"
                  item-value="id"
                  label="Choose Department"
                  dense
                  solo
                ></v-select>
              </v-col>
            </div>

            <v-card-text v-else>
              No staff is available to forward this chat !!
            </v-card-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#4b64ab"
              :disabled="!forward_department"
              dense
              @click.stop="onForwardUser"
              elevation="0"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      socket: io("https://server.support24.com.bd/"),
      user: {},
      all_department_list: [],
      send_support: false,
      content: "",
      messages: [],
      // selected: [2],
      forward_department: "",
      items: [],
      current_user_room_id: "",
      forward_room_id: "",
      current_user: {},
      current_message_array: [],
      current_last_order: {},
    }),

    methods: {
      // To forward the chat get available staffs related to this chat
      get_departments(user_room_id) {
        this.forward_room_id = user_room_id;
        axios
          .get(
            `https://server.support24.com.bd/get_all_departments/${user_room_id}`
          )
          .then((response) => {
            if (response.data.success) {
              this.all_department_list = response.data.all_department_list;
              this.send_support = true;
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      },

      closeDialog() {
        this.send_support = false;
      },

      // History
      onSeeHistory() {
        let routeData = this.$router.resolve({
          name: `OrderHistory`,
        });

        if (this.current_user.is_verified == 1) {
          routeData.href = `/supportdashboard/pages/order/history/?user_id=${this.current_user.site_user_id}`;
        } else {
          routeData.href = `/supportdashboard/pages/order/history/?non_verified_user_id=${this.current_user.site_user_id}`;
        }

        window.open(routeData.href, "_blank");
      },

      // Forward chat to a different department
      onForwardUser() {
        // console.log("forward_department_id", this.forward_department);
        // console.log("discussion_id", this.forward_room_id);

        let forward_information = {
          forward_department_id: this.forward_department,
          user_room_id: this.forward_room_id,
        };

        // axios
        //   .get(`https://server.support24.com.bd/get_all_departments/`)
        //   .then((response) => {
        //     if (response.data.success) {
        //       this.all_department_list = response.data.all_department_list;
        //       this.send_support = true;
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err.message);
        //   });

        this.socket.emit("forward", forward_information);

        this.closeDialog();
      },

      // Specific chat
      onSelectChat(item) {
        this.current_user_room_id = item.user_room_id;
        this.current_last_order = {};

        axios
          .get(
            `https://server.support24.com.bd/get_chat_details/${item.user_room_id}`
          )
          .then((response) => {
            let { success, user, message_list } = response.data;

            // console.log({ user });
            if (success) {
              this.current_user = user;
              this.current_message_array = message_list;

              let query = {};
              if (user.is_verified == 1) {
                query = {
                  user_id: user.site_user_id,
                };
              } else {
                query = {
                  non_verified_user_id: user.site_user_id,
                };
              }

              // {non_verified_user_id : 1}

              axios
                .post(`Cart/get_last_order_address/`, query)
                .then((order_response) => {
                  // console.log("Single User ", order_response.data);
                  if (order_response.data.success) {
                    this.current_last_order = order_response.data.order_data;

                    let date = new Date(
                      order_response.data.order_data.date_created
                    );

                    this.current_last_order = {
                      ...this.current_last_order,
                      date: date.toLocaleString(),
                      address: order_response.data.billing_data.address,
                      location: order_response.data.billing_data.location,
                      area: order_response.data.billing_data.area,
                      order_count: order_response.data.order_count,
                    };

                    // console.log("Last Order", this.current_last_order);
                  } else {
                    this.current_last_order = {};
                  }
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      sendMessage() {
        // Emit event
        if (this.content) {
          let message_obj = {
            content: this.content,
            user_room_id: this.current_user_room_id,
            user_id: this.user.id,
          };

          this.socket.emit("admin_joined_chat", message_obj);
          this.content = "";
        }
      },
    },

    mounted() {
      this.user = JSON.parse(localStorage.getItem("user"));
      // Dummy
      // {
      //   user_email : 'support@support.com',
      //   role : 'support',
      //   user_id : 2
      // }

      this.socket.emit("admin_joined", this.user);

      this.socket.on("get_chat_list", (result) => {
        // this id will be used to join in a chat
        this.user.id = result.id;

        /// All chat list
        if (result.all_chat_list.length) {
          this.items = result.all_chat_list;
          this.onSelectChat(this.items[0]);
        }
      });

      this.socket.on("get_message", (messageObj) => {
        // console.log({ messageObj });
        if (messageObj.user_room_id == this.current_user_room_id) {
          // console.log(result)
          let obj = this.items.find(
            (element) => element.user_room_id == messageObj.user_room_id
          );
          if (obj.status == "In Queue") {
            obj.status = "online";
          }

          this.current_message_array.unshift(messageObj);
        }
      });

      this.socket.on("new_chat", (new_chat_obj) => {
        // console.log({ new_chat_obj });
        this.items.unshift(new_chat_obj);
      });

      this.socket.on("forwarded_chat", (new_chat_obj) => {
        // console.log({ new_chat_obj });
        this.items.unshift(new_chat_obj);
      });

      this.socket.on("remove_chat_from_chat_list", (removed_chat_obj) => {
        // console.log({ removed_chat_obj });
        this.items = this.items.filter(
          (element) =>
            element.user_room_id != removed_chat_obj.removed_chat_room_id
        );
      });
    },
  };
</script>



<style scoped lang="scss">
  // List style

  .online-status {
    border: 1px solid red;
  }

  // Input elemnt
  input[type="text"] {
    width: 90%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: rgb(238, 237, 235);
  }

  input[type="text"]:focus {
    border: 1px solid #64b5f6;
    outline: none;
  }

  // Chat Box Design

  .message-container {
    overflow-y: auto;
  }

  .chat-text {
    padding: 6px 10px;
    border-radius: 6px 0 6px 0;
    position: relative;
    background: white;
    border: 2px solid rgba(100, 170, 0, 0.1);
    color: black;
    font-size: 12px;
    margin-top: 10px;
    width: 60%;
  }

  .chat-text-wraper .chat-text::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    /* border-top: 10px solid red; */
    border-top: 10px solid white;
    /* border-right: none; */
    border-left: none;
    bottom: -18px;
    /* right: 0px; */
    left: 0px;
  }

  .right-chat-text-wraper .chat-text::after {
    content: "";
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid white;
    border-right: none;
    bottom: -18px;
    right: 0px;
  }

  .chat-user {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: black;
    margin-top: 5px;
  }

  // .right-chat-text-wraper{
  //   min-width: 100%;
  // }

  .right-chat-text-wraper .right-chat-user {
    float: right;
  }
</style>